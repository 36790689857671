import logo from "./logo.svg";
import "./App.css";
import { ChakraProvider, Card, CardBody, Text, Input } from "@chakra-ui/react";
import axios from "axios";

function App() {
  return (
    <ChakraProvider>
      <div className="App">
        <Card>
          <CardBody>
            <Text>
              View a summary of all your customers over the last month.
            </Text>
          </CardBody>
        </Card>
        <Input />
      </div>
    </ChakraProvider>
  );
}

export default App;
